<template>
  <div class="ui_step">
    <h5>流程总览</h5>
    <el-steps :active="active" finish-status="success" align-center>
      <el-step title="认证信息"></el-step>
      <el-step title="营业信息"></el-step>
      <el-step title="账户信息"></el-step>
      <el-step title="签订协议"></el-step>
    </el-steps>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    }
  },
  props: {
    active: {
      type: Number
    }
  }
}
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
  .ui_step{
    padding: 20px;
    background-color: #fff;
    margin-top: 20px;
    >h5{
      margin-bottom: 20px;
      font-weight: 400;
    }
  }
</style>
