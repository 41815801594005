<template>
  <div>
    <step :active="active" />
    <div class="ui_businessMessage">
      <h5>营业信息：</h5>
      <el-form
        :model="accountForm"
        :rules="rules"
        ref="accountForm"
        label-width="150px"
        class="ui_ruleForm"
      >
        <!-- <el-form-item :label="$t('searchModule.Merchant_type')" prop="type" class="ui_ruleForm_item">
          <el-radio-group v-model="accountForm.type">
            <el-radio :label="3">企业/个体商户</el-radio>
            <el-radio :label="6">小微商户</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="营业执照" prop="businessType" class="ui_ruleForm_item">
          <el-radio-group v-model="accountForm.businessType">
            <el-radio :label="0">多合一营业执照</el-radio>
            <el-radio :label="1">普通营业执照</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item
          prop="accountName"
          label="账户名称"
          class="ui_ruleForm_item"
        >
          <el-input
            v-model="accountForm.accountName"
            placeholder="请填写与工商名称相同的账户名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="账户号码"
          prop="accountNo"
          class="ui_ruleForm_item"
        >
          <el-input
            v-model="accountForm.accountNo"
            placeholder="请输入银行账号"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="开户行"
          prop="accountCity"
          class="ui_ruleForm_item"
        >
          <!-- <el-input v-model="accountForm.businessAddress"></el-input> -->
          <el-cascader
            style="width: 400px"
            size="large"
            :options="options"
            v-model="accountForm.accountCity"
            @change="areaChange"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item
          label="所属支行"
          prop="accountBankNo"
          class="ui_ruleForm_item"
        >
          <el-select
            v-model="accountForm.accountBankNo"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in bankOptions"
              :key="item.identifier"
              :label="item.desc"
              :value="item.identifier"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="补充地址" class="ui_ruleForm_item">
          <el-input v-model="accountForm.supplyAddress" placeholder="（选填）请填写剩余说明部分"></el-input>
        </el-form-item>
        <el-form-item label="所属行业" class="ui_ruleForm_item">
          <el-select v-model="accountForm.region" placeholder="请选择">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
          <el-select v-model="accountForm.region" placeholder="请选择" style="margin-left: 20px;">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item label="" prop="userId">
          <div style="display: block">
            <p>对公结算材料: 如开户许可证等(支持jpg/png, 文件 &lt; 2MB)</p>
            <div style="display: inline-block; position: relative">
              <el-upload
                class="avatar-uploader"
                action="/acb/2.0/tenant/cmb/imgUpload"
                :show-file-list="false"
                :on-change="handleChange"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :http-request="uploadPicture"
                :data="{ type: 1 }"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label="账户认证"
          prop="transAmount"
          class="ui_ruleForm_item"
        >
          <span v-if="accountForm.accountAuthStatus == 1">已认证</span>
          <div v-if="accountForm.accountAuthStatus != 1">
            <el-button v-if="verifyStep === 1" type="primary" @click="verify(1)"
              >我们将在您的对公账户打一笔随机金额作为验证码用于验证</el-button
            >
            <div class="flex" v-if="verifyStep === 2">
              <div class="flex-1">
                请先完善上方必填信息，再点击右侧汇款验证按钮。
              </div>
              <div>
                <el-button type="primary" @click="verify(2)"
                  >汇款验证</el-button
                >
              </div>
            </div>
            <div class="flex" v-if="verifyStep === 3">
              <div>
                <el-input
                  v-model="accountForm.transAmount"
                  placeholder="请输入汇款数额"
                ></el-input>
              </div>
              <div class="flex-1">
                款项已发送，请留意您的短信及账户，并输入数额。
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="$router.push('/businessMessage?tenantId=' + tenantId)"
            >上一步</el-button
          >
          <span v-if="accountForm.accountAuthStatus == 1">
            <el-button class="ml10" type="primary" @click="aggrementSign()">下一步</el-button>
          </span>
          <div style="display: inline-block;margin-left: 10px;" v-if="accountForm.accountAuthStatus != 1">
            <el-button v-if="verifyStep != 3" type="primary" disabled
              >请先完成账户认证</el-button
            >
            <el-button
              v-if="verifyStep == 3"
              type="primary"
              @click="accountVerify()"
              >下一步</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { provinceAndCityData } from "element-china-area-data";
import step from "./step";
export default {
  components: { step },
  data() {
    let checkDetailsAddress = (rule, value, callback) => {
      if (
        this.accountForm.way.trim().length < 1 ||
        this.accountForm.number.trim().length < 1
      ) {
        callback("请输入详细地址");
      } else {
        callback();
      }
    };
    return {
      loading: false,
      options: provinceAndCityData,
      bankOptions: [],
      selectedOptions: [],
      radio: "1",
      checked: false,
      imageUrl: "",
      active: 2,
      accountForm: {
        accountName: "",
        accountCity: [],
        accountNo: "",
        accountBankNo: "",
        transAmount: "",
        accountAuthStatus: "", // 判断是否认证
        umsRegId: "",
      },
      rules: {
        accountName: [
          {
            required: true,
            message: "请填写与工商名称相同的账户名称",
            trigger: "blur",
          },
        ],
        accountNo: [
          { required: true, message: "请输入银行账号", trigger: "blur" },
        ],
        accountBankNo: [
          { required: true, message: "请选择工商名称", trigger: "blur" },
        ],
      },
      verifyStep: 1,
    };
  },
  mounted() {
    this.tenantId = this.$route.query.tenantId;
    this.imgOptions = {
      tenantId: this.tenantId,
    };
    this.$axios
      .get(
        "/acb/2.0/tenant/cmb/mechantAccountApply/byTenantId/" + this.tenantId
      )
      .then((res) => {
        if (res.state != 0) {
        } else {
          this.accountForm = {
            accountName: res.value.accountName,
            accountNo: res.value.accountNo,
            accountBankNo: res.value.accountBankNo,
            accountAuthStatus: res.value.accountAuthStatus,
            umsRegId: res.value.umsRegId,
          };
          if (res.value.mechantPictureList.length > 0) {
            this.imageUrl = res.value.mechantPictureList[0].pictureOssUrl;
          }
        }
      });
  },
  methods: {
    // 上传图片
    uploadPicture(data) {
      const file = new FormData();
      const image = new FormData();
      image.append("tenantId", this.tenantId);
      if (data.data.type == 1) {
        image.append("imgType", "0006");
      }
      image.append("step", 3);
      image.append("image", data.file);
      const opt = {
        method: "post",
        url: data.action,
        data: image,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
        success: (res) => {
        },
        fail: (res) => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    remoteMethod(query) {
      if (query !== "" && this.accountForm.accountCity.length > 0) {
        this.loading = true;
        const imgOptions = {
          areaCode: this.accountForm.accountCity[1].substring(0, 4),
          keyword: query,
        };
        this.$axios
          .get("/acb/2.0/tenant/cmb/queryBranchBankList", {
            data: imgOptions,
          })
          .then((res) => {
            if (res.state != 0) {
              this.loading = false;
            } else {
              this.bankOptions = res.value;
              this.loading = false;
            }
          });
      } else {
        this.options = [];
      }
    },
    areaChange(value) {},
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("submit!!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    handleChange(file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const suffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      const isJpg = suffix === ('jpg' || 'jpeg' || 'JPG' || 'JPEG')
      const isPng = suffix === ('png' || 'PNG')
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJpg  && !isPng) {
        this.$message.warning('图片格式错误，只支持jpg和png格式!')
        return false
      }
      if (!isLt2M) {
        this.$message.warning('上传图片大小不能超过 2M!')
        return false
      }
      return true
    },
    launchAccountVerify() {
      this.$axios
        .get("/acb/2.0/tenant/cmb/launchAccountVerify", {
          data: {
            tenantId: this.tenantId,
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            this.verifyStep = 3;
          }
        });
    },
    verify(step) {
      this.$refs["accountForm"].validate((valid) => {
        if (valid) {
          if (this.accountForm.umsRegId == "") {
            // 开过户的直接发起认证
            this.$axios
              .post("/acb/2.0/tenant/cmb/saveOrUpdateAccountApply", {
                data: {
                  tenantId: this.tenantId,
                  accountType: 1,
                  accountName: this.accountForm.accountName,
                  accountNo: this.accountForm.accountNo,
                  accountBankNo: this.accountForm.accountBankNo,
                  accountAuthStatus: 1,
                },
              })
              .then((res) => {
                if (res.state != 0) {
                  this.$alert(res.desc, this.$t('pop_up.Tips'), {
                    confirmButtonText: this.$t('pop_up.Determine'),
                  });
                } else {
                  this.launchAccountVerify(); // 发起验证 返回金额
                }
              });
          } else {
            this.launchAccountVerify(); // 发起验证 返回金额
          }
        } else {
          this.verifyStep = 2;
          return false;
        }
      });
    },
    accountVerify() {
      this.$axios
        .get("/acb/2.0/tenant/cmb/accountVerify", {
          data: {
            tenantId: this.tenantId,
            transAmount: "10",
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            this.aggrementSign(); // 调至第三方签约页面
          }
        });
    },
    aggrementSign() {
      this.$axios
        .get("/acb/2.0/tenant/cmb/aggrementSign", {
          data: {
            tenantId: this.tenantId,
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            window.location.href = res.value;
          }
        });
    },
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.ml10 {
  margin-left: 10px;
}
.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.ui_businessMessage {
  padding: 20px;
  margin-top: 20px;
  background-color: #fff;

  >h5 {
    margin-bottom: 20px;
    font-weight: 400;
  }
}

.ui_ruleForm_item {
  width: 50%;
}

.avatar-uploader /deep/ .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader /deep/ .el-upload:hover {
  border-color: #0f6eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.ui_realname_iphone {
  display: flex;
  justify-content: space-between;
}

.ui_detailsAddress {
  display: flex;
  justify-content: space-between;

  >div {
    width: 50%;

    >span {
      margin-left: 10px;
    }
  }

  /deep/ .el-input {
    width: 70%;
  }
}
</style>
